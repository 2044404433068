// src/utils/analytics.ts

import { trackEvent } from './gtm';
import { trackConversion } from './linkedInTag';
import { trackCompleteRegistration } from './metaPixel';

export const trackSignUp = (email: string) => {
  // Google Tag Manager tracking
  trackEvent('sign_up', {
    event_category: 'engagement',
    event_action: 'sign_up',
    event_label: 'Google Auth',
  });

  // LinkedIn conversion tracking
  trackConversion('sign_up');

  // Meta Pixel tracking
  trackCompleteRegistration(email);
};