// src/utils/gtm.ts

const isBrowser = typeof window !== 'undefined';

export const trackEvent = (
  eventName: string,
  eventParams: Record<string, string | number | boolean>,
  pageMetadata?: {
    referrer?: string;
    campaignSource?: string;
    campaignMedium?: string;
    campaignName?: string;
  }
) => {
  if (isBrowser && (window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: eventName,
      ...eventParams,
      page_referrer: pageMetadata?.referrer || document.referrer,
      campaign_source: pageMetadata?.campaignSource,
      campaign_medium: pageMetadata?.campaignMedium,
      campaign_name: pageMetadata?.campaignName,
    });
  } else {
    console.log('GTM tracking skipped (not in browser or dataLayer not found):', eventName, eventParams);
  }
};

export const trackPurchase = (
  transactionId: string,
  value: number,
  currency: string,
  items: Array<{ item_name: string; price: number; quantity: number }>
) => {
  if (isBrowser && (window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: transactionId,
        value: value,
        currency: currency,
        items: items,
      },
    });
  } else {
    console.log('GTM purchase tracking skipped (not in browser or dataLayer not found):', transactionId, value, currency, items);
  }
};